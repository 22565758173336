(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/set-branding/assets/javascripts/template-brand.js') >= 0) return;  svs.modules.push('/components/tipsen/set-branding/assets/javascripts/template-brand.js');
"use strict";

const {
  useEffect
} = React;
const {
  template
} = svs.core;
const {
  useDefinition
} = svs.components.tipsen.engine.utils;
const {
  useCurrentDraw
} = svs.components.tipsen.drawProvider.hooks;
const {
  useBranding
} = svs.components.tipsen.hooks;
const TemplateBrand = _ref => {
  let {
    draw,
    drawCompetitionId,
    drawId
  } = _ref;
  const branding = useBranding(drawId, drawCompetitionId);
  const [productBranding] = branding;
  const definition = useDefinition();
  const currentDraw = useCurrentDraw();
  const drawToUse = draw || currentDraw;
  useEffect(() => {
    if (!Array.isArray(branding) || !branding.length) {
      return;
    }
    if (drawToUse) {
      const displayName = definition.getDisplayNameByDraw(drawToUse);
      const brandLogo = definition.getBrandLogoByDraw(drawToUse);
      template.updateConfig({
        tabs: {
          branding: "".concat(productBranding, "-main-nav")
        },
        headerClass: "header-".concat(productBranding),
        title: {
          row1: {
            brand: brandLogo === 'stryktipset' ? 'stryktipset-90' : brandLogo,
            text: displayName,
            alt: displayName
          }
        }
      });
    }
    document.querySelector('html').classList.add(...branding);
    return () => {
      document.querySelector('html').classList.remove(...branding);
    };
  }, [branding, productBranding, definition, drawToUse]);
  return null;
};
TemplateBrand.propTypes = {
  draw: PropTypes.object,
  drawCompetitionId: PropTypes.number,
  drawId: PropTypes.string
};
setGlobal('svs.components.tipsen.setBranding.TemplateBrand', TemplateBrand);

 })(window);